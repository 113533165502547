import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DefaultVoipObject {
  voipProductSelected?: boolean;
  id?: string;
  productCode?: string;
  productName?: string;
  priceResult?: Array<any>;
  billingRateProductId?: string;
}
interface InternetProductsState {
  products: any;
  productKeys: any;
  displaySpinner: boolean;
  currentProduct: any;
  cms: any;
  cmsFiberPlans: any;
  productResponse: any;
  addOnProducts: any;
  internetError: boolean;
  displayComparePlanLink: any;
  footNote: any;
  displayFootNote: any;
  oneTime: boolean;
  locale: any;
  voip: any;
  lineItems: any;
  helpText: any;
  ProductOptionalNote: any;
  cancelFlow: boolean;
  cmsVoicePlans: any;
  selectProduct: any;
  existingProductPrice: any;
  staticId: any;
  defaultVoipCard: boolean;
  defaultVoiceCard: boolean;
  productExist: any;
  recommendedProduct: any;
  cardType: string;
  // Voice product related state
  portabilityState: any;
  isPortable: any;
  agreement: any;
  serviceLimitationAgreement: boolean;
  brightspeedFiberSubscriberAgreement: any;
  errors: any;
  mandatory: boolean;
  lnpDetails: any;
  currentLineItem:DefaultVoipObject;
  defaultSelectedProduct: boolean;

  // Check interent and voice product exist
  internetExist: boolean,
  voiceExist: boolean
}

const initialState: InternetProductsState = {
  products: null,
  productKeys: null,
  displaySpinner: false,
  currentProduct: null,
  cms: null,
  cmsFiberPlans: null,
  productResponse: null,
  addOnProducts: null,
  internetError: false,
  displayComparePlanLink: true,
  footNote: null,
  displayFootNote: null,
  oneTime: false,
  locale: "en-US",
  voip: {},
  lineItems: [],
  helpText: "",
  ProductOptionalNote: "",
  cancelFlow: false,
  cmsVoicePlans: null,
  selectProduct: null,
  existingProductPrice: null,
  staticId: null,
  defaultVoipCard: false,
  defaultVoiceCard: false,
  defaultSelectedProduct: false,
  productExist: null,
  recommendedProduct: null,
  cardType: "internet",

  // Voice product related state
  portabilityState: {
    customerPhone: "",
    accountId: "",
    accountHolderName: "",
    pinNumber: "",
  },
  isPortable: null,
  agreement: null,
  serviceLimitationAgreement: false,
  brightspeedFiberSubscriberAgreement: null,
  errors: {
    customerPhoneError: "",
    accountIdError: "",
    accountHolderNameError: "",
    pinNumberError: "",
    agreementError: "",
    serviceLimitationAgreementError: "",
    brightspeedFiberSubscriberAgreementError: "",
  },
  mandatory: false,
  lnpDetails: {
    currentLineItem: {},
    mandatory: false,
    requiredFields: false,
    voiceConditions: false,
    voiceValidations: false,
    checkBoxFocus: false
  },
  currentLineItem: {
    "voipProductSelected": false,
    "id": "",
    "productCode": "",
    "priceResult": [],
    "billingRateProductId": "",
    "productName": ""
  },
  internetExist: false,
  voiceExist: false
};

const internetProductsSlice = createSlice({
  name: 'internetProducts',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<any>) {
      state.products = action.payload;
    },
    setProductKeys(state, action: PayloadAction<any>) {
      state.productKeys = action.payload;
    },
    setDisplaySpinner(state, action: PayloadAction<boolean>) {
      state.displaySpinner = action.payload;
    },
    setCurrentProduct(state, action: PayloadAction<any>) {
      state.currentProduct = action.payload;
    },
    setCms(state, action: PayloadAction<any>) {
      state.cms = action.payload;
    },
    setCmsFiberPlans(state, action: PayloadAction<any>) {
      state.cmsFiberPlans = action.payload;
    },
    setProductResponse(state, action: PayloadAction<any>) {
      state.productResponse = action.payload;
    },
    setAddOnProducts(state, action: PayloadAction<any>) {
      state.addOnProducts = action.payload;
    },
    setInternetError(state, action: PayloadAction<boolean>) {
      state.internetError = action.payload;
    },
    setPortabilityState(state, action: PayloadAction<any>) {
      state.portabilityState = {
        ...state.portabilityState,
        ...action.payload
      }
    },
    setIsPortable(state, action: PayloadAction<any>) {
      state.isPortable = action.payload;
    },
    setAgreement(state, action: PayloadAction<any>) {
      state.agreement = action.payload;
    },
    setServiceLimitationAgreement(state, action: PayloadAction<any>) {
      state.serviceLimitationAgreement = action.payload;
    },
    setBrightspeedFiberSubscriberAgreement(state, action: PayloadAction<any>) {
      state.brightspeedFiberSubscriberAgreement = action.payload;
    },
    setErrors(state, action: PayloadAction<any>) {
      state.errors = {
        ...state.errors,
        ...action.payload
      }
    },
    setMandatory(state, action: PayloadAction<any>) {
      state.mandatory = action.payload;
    },
    setLnpDetails(state, action: PayloadAction<any>) {
      state.lnpDetails = {
        ...state.lnpDetails,
      ...action.payload
    }
    },
    setCurrentLineItem(state, action: PayloadAction<any>) {
      state.currentLineItem = action.payload;
    },
    setCardType(state, action: PayloadAction<any>) {
      state.cardType = action.payload;
    },
    setCmsVoicePlans(state, action: PayloadAction<any>) {
      state.cmsVoicePlans = action.payload;
    },
    setCompareLink(state, action: PayloadAction<any>) {
      state.displayComparePlanLink = action.payload;
    },
    setCancelFlow(state, action: PayloadAction<any>) {
      state.cancelFlow = action.payload;
    },
    setSelectProduct(state, action: PayloadAction<any>) {
      state.defaultSelectedProduct = action.payload;
    },
    setDefaultVoice(state, action: PayloadAction<any>) {
      state.defaultVoiceCard = action.payload;
    },
    setInternetExist(state, action: PayloadAction<any>) {
      state.internetExist = action.payload;
    },

    setVoiceExist(state, action: PayloadAction<any>) {
      state.voiceExist = action.payload;
    },
  }
});

export const {
  setProducts,
  setProductKeys,
  setDisplaySpinner,
  setCurrentProduct,
  setCms,
  setCmsFiberPlans,
  setProductResponse,
  setAddOnProducts,
  setInternetError,
  setPortabilityState,
  setIsPortable,
  setAgreement,
  setServiceLimitationAgreement,
  setBrightspeedFiberSubscriberAgreement,
  setErrors,
  setMandatory,
  setLnpDetails,
  setCurrentLineItem,
  setCardType,
  setCmsVoicePlans,
  setCompareLink,
  setCancelFlow,
  setSelectProduct,
  setDefaultVoice,
  setInternetExist,
  setVoiceExist
} = internetProductsSlice.actions;

export default internetProductsSlice.reducer;
